import { APP_BASE_HREF, CommonModule, CurrencyPipe, PlatformLocation } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecipeConstants } from '@gfs/constants';
import {
    AddItemsModule,
    CustomerUnitSelectionModule,
    GeneralLedgerEffects,
    HeaderModule,
    ManageCustomItemsModule,
    MessageContentComponent,
    MessagesComponent,
    QuickAddItemsModule
} from '@gfs/shared-components';
import {
    APPLICATION_USER_ROLE,
    SelectedEntitlementObservableDependency,
    applicationUserRoleFactory,
    RECIPE_PROFIT_CALCULATOR_CONFIG,
} from '@gfs/shared-models';
import {
    AppConfigService,
    AppFeatureFlags,
    appFeatureFlagsFactory,
    AuthorizationInterceptor,
    recipeProfitCalculatorConfigurationFactory,
    getAPIBaseUrl,
    getInventoryAPIBaseUrl,
    InjectionTokens,
    employeeOIDCConfigFactory,
    oktaConfigFactory,
    WINDOW,
    WINDOW_PROVIDERS
} from '@gfs/shared-services';
import { AuthEffects, IAuthStateFacade, LayoutEffects } from '@gfs/store/common';
import { AddItemsStateModule, AddItemsStoreEffects } from '@gfs/store/feature/add-items';
import { CartEffects } from '@gfs/store/feature/cart';
import { CustomerUnitSelectionStateModule } from '@gfs/store/feature/customer-unit-selection';
import { QuickAddItemsStateModule } from '@gfs/store/feature/quick-add-items';
import { CustomerItemsEffects } from '@gfs/store/inventory/effects/customerItems.effects';
import { InventoryItemsEffects } from '@gfs/store/inventory/effects/inventoryItems.effects';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { AppEffects } from '@gfs/store/recipe/effects/app.effects';
import { CategoryEffects } from '@gfs/store/recipe/effects/category.effects';
import { MeasurementUnitEffects } from '@gfs/store/recipe/effects/measurementUnit.effects';
import { RecipeEffects } from '@gfs/store/recipe/effects/recipe.effects';
import { reducers } from '@gfs/store/recipe/reducers';
import { LocalizedUIV2Module } from '@gfs/v2/shared-components';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    OktaAuthModule,
    OKTA_CONFIG
} from '@okta/okta-angular';
import { InventoryDataProviderService } from '@recipe-ui/architect/inventory-data-provider.service';
import { environment } from '@recipe-ui/environment/environment';
import { PipeSharingModule } from '@recipe-ui/pipes/pipe-sharing-module/pipe-sharing.module';
import { AddItemsContextFactoryService } from '@recipe-ui/services/architect/add-items-context-factory.service';
import { CustomerUnitSelectionFactoryService } from '@recipe-ui/services/architect/customer-unit-selection-context-factory.service';
import { InventoryAddItemsModalAppSettingsProviderServiceService } from '@recipe-ui/services/architect/inventory-add-items-modal-app-settings-provider-service.service';
import { QuickAddItemsContextFactoryService } from '@recipe-ui/services/architect/quick-add-items-context-factory.service';
import { UserContextFactoryService } from '@recipe-ui/services/architect/recipe-user-context-factory.service';
import { UIModule } from '@recipe-ui/ui/ui.module';
import { InventoryV2Module } from 'apps/inventory-ui/src/app/v2/v2.module';
import { RecipeV2Module } from 'apps/recipe-ui/src/app/v2/v2.module';
import { DefaultItemUnitConfigurationFactory, ItemUnitConfiguration } from 'libs/shared-services/src/v2/services/item/unit-source-config';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { SelectedCustomerUnitFactoryService } from 'libs/shared-services/src/v2/services/selected-customer-unit-factory.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoryModule } from './category/category.module';
import { ErrorModule } from './error/error.module';
import { MaterialModule } from './material.module';
import { RecipeModule } from './recipe/recipe.module';
import { GlobalDialogsService } from './services/architect/global-dialogs.service';
import { AuthResolver } from '../../../../libs/shared-services/src/lib/resolvers/auth-resolver';
import { RecipeImageUploadConfig } from './ui/photo-manager/photo-manager.model';
import { CategoryRecipesCountPipe } from './pipes/pipe-sharing-module/category-recipes-count.pipe';
import { RecipeNameFilterPipe } from './pipes/pipe-sharing-module/recipe-name-filter.pipe';
import {
    AuthenticationService,
    OIDCAuthenticationModule,
    NamAuthenticationService,
    NAM_OIDC_CONFIG,
    OpenIDConnectService,
    OktaAuthenticationService
} from '@gfs/shared-services/auth';
import { RecipeProfitCalculatorModule } from 'libs/shared-components/src/lib/recipe-profit-calculator/index';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

const tenMegabytes = 10485760;

@NgModule({
    declarations: [
        AppComponent,
        MessagesComponent,
        MessageContentComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatTabsModule,
        MatButtonModule,
        MaterialModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        CategoryModule,
        HeaderModule,
        RecipeModule,
        CommonModule,
        ManageCustomItemsModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        OktaAuthModule,
        PipeSharingModule,
        ErrorModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                // We are manually disabling the immutability checks introduced with angular 9
                // using html templates with store models is causing regression issues in angular 9+
                // In the future, it would be good to re-enable these checks to enforce good coding practices.
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        connectInZone: true}),
        EffectsModule.forRoot([
            AppEffects,
            AuthEffects,
            LayoutEffects,
            CategoryEffects,
            RecipeEffects,
            MeasurementUnitEffects,
            AddItemsStoreEffects,
            WorksheetEffects,
            CustomerItemsEffects,
            InventoryItemsEffects,
            CartEffects,
            GeneralLedgerEffects
        ]),
        AddItemsModule,
        AddItemsStateModule,
        CustomerUnitSelectionStateModule,
        CustomerUnitSelectionModule,
        QuickAddItemsModule,
        QuickAddItemsStateModule,
        UIModule,
        InventoryV2Module,
        RecipeV2Module,
        LocalizedUIV2Module,
        RecipeProfitCalculatorModule,
        OIDCAuthenticationModule,
    ],
    providers: [
        AuthResolver,
        WINDOW_PROVIDERS,
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation],
        },
        Title,
        TranslateService,
        AppConfigService,
        {
            provide: OKTA_CONFIG,
            useFactory: oktaConfigFactory,
            deps: [AppConfigService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            deps: [
                AppConfigService,
                Store<IAuthStateFacade>
            ],
            multi: true,
        },
        {
            provide: InjectionTokens.API_BASE_URL,
            useFactory: getAPIBaseUrl,
            deps: [AppConfigService],
        },
        {
            provide: InjectionTokens.INVENTORY_API_BASE_URL,
            useFactory: getInventoryAPIBaseUrl,
            deps: [AppConfigService],
        },
        {
            provide: InjectionTokens.IAPP_CONTEXT,
            useFactory: UserContextFactoryService.factory,
            deps: [UserContextFactoryService],
        },
        {
            provide: InjectionTokens.IAddItemsFeatureBridge,
            useFactory: AddItemsContextFactoryService.factory,
            deps: [AddItemsContextFactoryService],
        },
        {
            provide: InjectionTokens.IQuickAddItemsFeatureBridge,
            useFactory: QuickAddItemsContextFactoryService.factory,
            deps: [QuickAddItemsContextFactoryService],
        },
        {
            provide: InjectionTokens.ICustomerUnitSelectionBridge,
            useFactory: CustomerUnitSelectionFactoryService.factory,
            deps: [CustomerUnitSelectionFactoryService],
        },
        {
            provide: SelectedEntitlementObservableDependency,
            useFactory: SelectedCustomerUnitFactoryService.factory,
            deps: [SelectedCustomerUnitFactoryService],
        },
        {
            provide: InjectionTokens.IAddItemsModalAppSettingsProviderService,
            useClass: InventoryAddItemsModalAppSettingsProviderServiceService,
        },
        {
            provide: InjectionTokens.IGlobalDialogService,
            useClass: GlobalDialogsService,
        },
        {
            provide: InjectionTokens.ACTIVE_APPLICATION,
            useValue: RecipeConstants.APPLICATION_KEY
        },
        {
            provide: AppFeatureFlags,
            deps: [AppConfigService],
            useFactory: appFeatureFlagsFactory
        },
        CurrencyPipe,
        {
            provide: InjectionTokens.IInventoryDataProviderService,
            useFactory: InventoryDataProviderService.factory,
            deps: [InventoryDataProviderService],
        },
        {
            provide: RecipeImageUploadConfig,
            useValue: ({
                maxSizeBytes: tenMegabytes,
                allowedMimeTypes: [
                    'image/png',
                    'image/jpeg',
                ],
                maxImageCount: 3
            } as RecipeImageUploadConfig)
        },
        {
            provide: ItemUnitConfiguration,
            useValue: DefaultItemUnitConfigurationFactory.build()
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (unitsSvc: UnitsHttpService) => () => unitsSvc.init(),
            deps: [UnitsHttpService],
            multi: true
        },
        CategoryRecipesCountPipe,
        RecipeNameFilterPipe,
        {
            provide: APPLICATION_USER_ROLE,
            useFactory: applicationUserRoleFactory,
            deps: [WINDOW],
        },
        {
            provide: NAM_OIDC_CONFIG,
            useFactory: employeeOIDCConfigFactory,
            deps: [
                InjectionTokens.ACTIVE_APPLICATION,
                AppConfigService,
                OpenIDConnectService,
                APPLICATION_USER_ROLE
            ],
        },
        {
            provide: RECIPE_PROFIT_CALCULATOR_CONFIG,
            useFactory: recipeProfitCalculatorConfigurationFactory,
            deps: [
                APPLICATION_USER_ROLE,
                AppConfigService,
            ],
        },
        {
            provide: AuthenticationService,
            useFactory: AuthenticationService.factory,
            deps: [
                APPLICATION_USER_ROLE,
                OktaAuthenticationService,
                NamAuthenticationService
            ]
        },
        NamAuthenticationService,
        OktaAuthenticationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
